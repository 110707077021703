




























































































































































































import { Vue, Component, VModel, Prop, Watch, Emit, ProvideReactive } from 'vue-property-decorator';
import Uppy, { UppyFile } from '@uppy/core';
import FileInput from '@uppy/file-input';
import { Dashboard } from '@uppy/vue';
import { DashboardOptions } from '@uppy/dashboard';
import { inject } from 'inversify-props';
import { cloneDeep, orderBy, toLower, random } from 'lodash';
import { LoaderComponent } from 'vue-loading-overlay';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import ConversationService from '@/services/crm/conversation.service';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import ConversationModel from '@/models/crm/conversation.model';
import CrmChatDialogMessage from '@/components/crm/chat-dialog-message.vue';
import { IKeyValue } from '@/interfaces/key-value.interface';
import dayjs from '@/plugins/dayjs';
import RouterService from '@/services/router.service';
import ClientService from '@/services/crm/client.service';
import ClientModel from '@/models/crm/client.model';
import ConversationMessageModel from '@/models/crm/conversation-message.model';
import ConversationLogModel from '@/models/crm/conversation-log.model';
import { ConversationMessageOriginEnum } from '@/enums/crm/conversation-message-origin.enum';
import { ConversationMessageStatusEnum } from '@/enums/crm/conversation-message-status.enum';
import { ConversationTypeEnum } from '@/enums/crm/conversation-type.enum';
import ConversationTemplateModel from '@/models/crm/conversation-template.model';
import Tooltip from '@/components/tooltip.vue';
import SessionService from '@/services/session.service';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import ContentDialog from '@/components/content-dialog.vue';
import CrmChatHistoryMessagesViewer from '@/components/crm/chat-history-messages-viewer.vue';
import { StringHelper } from '@/utils/helpers/string-helper';
import ContactModel from '@/models/crm/contact.model';
import ContactService from '@/services/crm/contact.service';
import CrmChatSelectClientProspectForm from './chat-select-client-prospect-form.vue';
import ProspectModel from '@/models/crm/prospect.model';

type ChatVariables = {
  isOpen: boolean;
  isUploadingFile: boolean;
  media: UppyFile[];
};

@Component({
  components: {
    Tooltip,
    CrmChatDialogMessage,
    Dashboard,
    ContentDialog,
    CrmChatHistoryMessagesViewer,
    CrmChatSelectClientProspectForm,
  },
})
export default class CrmChatDialog extends Vue {
  @inject(InjectionIdEnum.SessionService)
  private sessionService!: SessionService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @inject(InjectionIdEnum.CrmConversationService)
  protected conversationService!: ConversationService;

  @inject(InjectionIdEnum.CrmClientService)
  protected clientService!: ClientService;

  @inject(InjectionIdEnum.CrmContactService)
  protected contactService!: ContactService;

  @Prop({ required: true })
  userContactInfo!: UserContactInfo;

  @ProvideReactive('activeClient')
  activeClient: ClientModel | null = null;

  @ProvideReactive('clientType')
  clientType = this.sessionService.clientType;

  @VModel({ required: true })
  model!: ChatVariables;

  @Prop({ required: true })
  conversation!: ConversationModel;

  @Prop({ default: () => [] })
  templates!: ConversationTemplateModel[];

  @Prop()
  blocked!: boolean;

  @Prop()
  loading!: boolean;

  @Prop()
  expand!: boolean;

  @Prop()
  refreshLocatedContacts!: string;

  private existingContacts: ContactModel[] = [];

  selectClientProspect = false;

  activeRoute: string | null | undefined = null;

  protocolDialogConversation!: ConversationModel | null;

  isProtocolOpen = false;

  messagesDialogTitle = '';

  messageOrigin = ConversationMessageOriginEnum;

  messageStatus = ConversationMessageStatusEnum;

  private loader: LoaderComponent | null = null;

  private endOfScrollPosition = true;

  private hideScrollToFinishBtn = true;

  private resizeObserver: ResizeObserver | null = null;

  private messagesRef: Element | null = null;

  private messagesContainerRef: Element | null = null;

  uppyInstance: Uppy | null = null;

  @Watch('refreshLocatedContacts', { immediate: true })
  doRefreshLocatedContacts(): void {
    this.onConversationChange();
  }

  @Watch('conversation', { immediate: true })
  async onConversationChange(): Promise<void> {
    this.existingContacts = [];
    if (!this.conversation.cnpj && !this.conversation.prospect) {
      try {
        this.existingContacts = await this.contactService.verifyWaIdContacts(
          null,
          this.conversation.numeroWhatsapp,
          null,
          null,
          false,
        );
        if (this.existingContacts === undefined) {
          this.existingContacts = [];
        }

        const clientContacts: ContactModel[] = await this.contactService.verifyWaIdContactsClient(
          undefined,
          this.conversation.numeroWhatsapp,
          false,
        );
        clientContacts.forEach((contact) => {
          if (this.existingContacts.find((c) => c.cnpj === contact.cnpj)) return;
          this.existingContacts.push(contact);
        });

        const prospectContacts: ContactModel[] = await this.contactService.verifyWaIdContactsProspect(
          undefined,
          this.conversation.numeroWhatsapp,
          false,
        );
        prospectContacts.forEach((contact) => {
          if (this.existingContacts.find((c) => c.idProspect === contact.idProspect)) return;
          this.existingContacts.push(contact);
        });
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      }
    }
  }

  @Watch('model.isOpen', { immediate: true })
  async onModelChange(value: boolean): Promise<void> {
    if (value) {
      this.hideScrollToFinishBtn = true;

      setTimeout(() => {
        this.hideScrollToFinishBtn = false;
      }, 750);

      setTimeout(() => {
        if (this.messagesRef && this.messagesContainerRef) {
          this.messagesRef.scrollTo({ top: this.messagesContainerRef.clientHeight });
        }
      }, 250);
    }
  }

  @Watch('loading', { immediate: true })
  onLoadingChange(active: boolean): void {
    this.setLoader(active);
  }

  @Emit('open-contact')
  onOpenContact(): ConversationModel {
    return this.conversation;
  }

  @Emit('expand-change')
  onClickExpand(): boolean {
    return !this.expand;
  }

  @Emit('set-client')
  onSetConversationClient(): ContactModel {
    this.existingContacts = [];
    return this.conversation.contato;
  }

  @Emit('cancel-set-client')
  onCancelSetClient(): ConversationModel {
    return this.conversation;
  }

  async onLoadPanel(): Promise<void> {
    const hasClient = this.conversation.cliente && this.conversation.cliente.type === ClientTypeEnum.Client;
    this.clientType = hasClient ? ClientTypeEnum.Client : ClientTypeEnum.Prospect;
    this.sessionService.clientType = this.clientType;

    const codProspect = this.conversation.prospect
      ? this.conversation.prospect.codProspect
      : this.conversation.cliente.codCliente;

    if (
      hasClient
      && (!this.activeClient
        || (this.activeClient && this.activeClient.cnpjCpf !== this.conversation.cliente.cnpjCpf)
        || this.clientType !== this.routerService.route().query.clientType)
    ) {
      await this.loadClient(this.conversation.cliente.cnpjCpf);
      if (this.activeClient && this.activeClient.cnpjCpf === this.conversation.cliente.cnpjCpf) {
        this.sessionService.clientType = this.activeClient.type;
      }
    } else if (
      !hasClient
      && (!this.activeClient
        || (this.activeClient && this.activeClient.codCliente !== codProspect)
        || this.clientType !== this.routerService.route().query.clientType)
    ) {
      await this.loadClient(codProspect);
      if (this.activeClient && this.activeClient.codCliente === codProspect) {
        this.sessionService.clientType = this.activeClient.type;
      }
    }
    if (this.activeClient) {
      this.sessionService.activeClient = this.activeClient;
    }

    this.routerService.navigate({
      name: 'CrmDashboard',
      params: {
        clientId: hasClient ? this.sessionService.activeClient.cnpjCpf : this.sessionService.activeClient.codCliente,
      },
      query: { clientType: this.sessionService.clientType },
    });
  }

  created(): void {
    if (!this.uppyInstance) {
      this.uppyInstance = this.getUppyInstance();
      this.uppyInstance.use(FileInput, {
        pretty: true,
        inputName: 'files[]',
      });
    }
  }

  async mounted(): Promise<void> {
    if (!this.resizeObserver) {
      this.resizeObserver = new ResizeObserver(() => {
        if (this.endOfScrollPosition && this.messagesRef && this.messagesContainerRef) {
          this.messagesRef.scrollTo({ top: this.messagesContainerRef.clientHeight });
        }
      });
    }

    this.messagesRef = this.$refs.chatDialogMessages as Element;
    this.messagesContainerRef = this.$refs.chatDialogMessagesContainer as Element;

    if (this.messagesContainerRef) {
      this.resizeObserver.observe(this.messagesContainerRef);
    }
  }

  private async loadClient(clientId: string): Promise<void> {
    try {
      const client = await this.clientService.getSummary(clientId, this.sessionService.clientType);
      client.nomeFantasia = (client.nomeFantasia || '').trim() || (client.nome || '').trim();
      this.activeClient = client;
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  private getUppyInstance(): Uppy {
    const uppy = new Uppy({
      locale: {
        strings: {
          dropHint: 'Solte seus arquivos aqui',
          dropPasteFiles: 'Solte seus arquivos aqui ou clique para colar (Ctrl+V)',
          browseFiles: 'Selecione...',
          dropPasteImportFiles: '',
          cancel: 'Cancelar',
          removeFile: 'Remover arquivo',
          editFile: 'Editar %{file}',
          editing: 'Editando %{file}',
          saveChanges: 'OK',
          uploadXFiles: {
            0: 'Enviar %{smart_count} arquivo',
            1: 'Enviar %{smart_count} arquivos',
          },
          xFilesSelected: {
            0: '%{smart_count} arquivo selecionado',
            1: '%{smart_count} arquivos selecionados',
          },
          youCanOnlyUploadFileTypes: 'Você só pode adicionar arquivos de tipo: %{types}',
          youCanOnlyUploadX: {
            0: 'Você só pode adicionar até 30 arquivos',
          },
          noMoreFilesAllowed: 'Você só pode adicionar até 30 arquivos',
          exceedsSize: '%{file} excede o tamanho máximo de arquivo(%{size})',
          youHaveToAtLeastSelectX: {
            0: 'Você precisa adicionar pelo menos um arquivo',
          },
        },
      },
      id: `${dayjs().format()}${random(1, 15)}`,
      autoProceed: false,
      restrictions: {
        maxNumberOfFiles: 30,
        minNumberOfFiles: 1,
      },
      onBeforeFileAdded: (currentFile) => {
        const modifiedFile = {
          ...currentFile,
          name: StringHelper.formatFilename(currentFile.name),
        };
        return modifiedFile;
      },
    })
      .on('file-removed', (currentFile) => {
        if (this.uppyInstance && this.uppyInstance.getFiles()[0] !== undefined && this.model.isUploadingFile) {
          uppy.removeFile(currentFile.id);
          this.model.media = this.model.media.filter((media) => media.name !== currentFile.id);
        }
        return false;
      })
      .on('file-added', (currentFile) => {
        const imageVideoTypes: Array<string> = ['image/jpg', 'image/jpeg', 'image/png', 'video/mp4', 'video/3gpp'];
        if ((currentFile.type?.includes('image') || currentFile.type?.includes('video'))
          && !imageVideoTypes.find((t) => t === currentFile.type)) {
          console.log(imageVideoTypes.find((t) => t === currentFile.type));
          uppy.removeFile(currentFile.id);
          if (currentFile.type?.includes('image')) {
            this.$notify.error(this.$t('crm.chat.messages.imageUploadExtensionError'));
          } else {
            this.$notify.error(this.$t('crm.chat.messages.videoUploadExtensionError'));
          }
          return false;
        }
        if (
          (currentFile.type === 'image/jpg' || currentFile.type === 'image/jpeg' || currentFile.type === 'image/png')
          && currentFile.size > 4718592
        ) {
          uppy.removeFile(currentFile.id);
          this.$notify.error('Tamanho máximo de 4.5Mb para arquivos jpg, jpeg ou png');
          return false;
        }
        if ((currentFile.type === 'video/mp4' || currentFile.type === 'video/3gpp') && currentFile.size > 16252928) {
          uppy.removeFile(currentFile.id);
          this.$notify.error('Tamanho máximo de 15.5Mb para arquivos mp4 ou 3gpp');
          return false;
        }
        if (
          !currentFile.type?.includes('video')
          && !currentFile.type?.includes('image')
          && currentFile.size > 67108864
        ) {
          uppy.removeFile(currentFile.id);
          this.$notify.error('Tamanho máximo de 64Mb para documentos');
        }
        return true;
      })
      .on('upload', () => {
        uppy.getFiles().forEach((file) => {
          this.model.media.push(file);
        });
        this.model.media.forEach((file) => {
          uppy.removeFile(file.id);
        });
        this.model.isUploadingFile = false;
      })
      .on('cancel-all', () => {
        const ids = new Array<string>();
        uppy.getFiles().forEach((file) => ids.push(file.id));
        ids.forEach((id) => {
          uppy.removeFile(id);
        });
        this.model.media = [];
      });
    return uppy;
  }

  onFinalScrolling(entries: IntersectionObserverEntry, observer: IntersectionObserver, isIntersecting: boolean): void {
    this.endOfScrollPosition = isIntersecting;
  }

  onUpdateChat(): void {
    this.$emit('update-chat');
  }

  onScrollToFinish(): void {
    if (this.messagesRef && this.messagesContainerRef) {
      this.messagesRef.scrollTo({ top: this.messagesContainerRef.clientHeight });
    }
  }

  onDeleteMessage(message: ConversationMessageModel): void {
    this.$emit('delete-message', message);
  }

  onRetrySendText(message: ConversationMessageModel): void {
    this.$emit('retry-send-text', message);
  }

  onRetrySendTemplate(template: ConversationTemplateModel): void {
    this.$emit('retry-send-template', template);
  }

  onCloseChat(): void {
    this.model.isOpen = !this.model.isOpen;
  }

  onCloseUploadFile(): void {
    this.uppyInstance?.cancelAll();
    this.model.isUploadingFile = false;
  }

  onMessageDialogClose(): void {
    this.isProtocolOpen = false;
    this.protocolDialogConversation = null;
  }

  async onOpenProtocol(conversation: number): Promise<void> {
    this.protocolDialogConversation = await this.conversationService.getConversation(conversation, true);
    const protocol = (this.protocolDialogConversation as ConversationModel)?.protocolo;
    this.messagesDialogTitle = this.$t('crm.chatHistoryViewer.dialog.messages.title', { protocol }).toString();
    this.isProtocolOpen = true;
  }

  unmounted(): void {
    this.uppyInstance?.cancelAll();
    if (this.resizeObserver && this.messagesContainerRef) {
      this.resizeObserver.unobserve(this.messagesContainerRef);
    }
  }

  get dialogList(): Array<ConversationMessageModel | ConversationLogModel> {
    const messages = this.conversation.messages
      .filter((x) => x.id)
      .map((x) => {
        const obj = x;
        if (obj.reply?.id) {
          const replyMessage = cloneDeep(this.conversation.messages.find((y) => y.id === obj.reply?.id));
          if (replyMessage) {
            replyMessage.reply = undefined;
            obj.reply = replyMessage;
          }
        }
        return obj;
      });

    return orderBy([...messages, ...this.conversation.logs], ['date', 'id', 'isLog'], ['asc', 'asc', 'desc']);
  }

  get clientName(): string {
    let client = '';
    if (this.conversation?.tipo === ConversationTypeEnum.Prospect) {
      client = this.conversation?.cliente?.nome
        || this.conversation?.cliente?.nomeFantasia
        || this.conversation?.prospect?.razaoSocial
        || this.conversation?.prospect?.fantasia;
    } else {
      client = this.conversation?.cliente?.nome
        || this.conversation?.cliente?.nomeFantasia;
    }

    if (client && client.length > 20) {
      client = `${client.substring(0, 17)}...`;
    }

    return client;
  }

  get phoneMask(): string {
    return this.conversation?.numeroWhatsapp?.length > 12 ? '+## (##) #####-####' : '+## (##) ####-####';
  }

  get dateSeparator(): IKeyValue<string> {
    const data: IKeyValue<string> = {};

    let previousDate: dayjs.Dayjs;
    this.dialogList.forEach((item, index) => {
      const currentDate = dayjs(item.date);

      if (index === 0 || (previousDate && !previousDate.isSame(currentDate, 'date'))) {
        if (dayjs().isSame(currentDate, 'date')) {
          data[index] = this.$t('crm.chatDialog.label.today').toString();
        } else if (dayjs().subtract(1, 'day').isSame(currentDate, 'date')) {
          data[index] = this.$t('crm.chatDialog.label.yesterday').toString();
        } else {
          data[index] = this.$t('crm.chatDialog.dateSeparator', {
            day: currentDate.format('DD'),
            month: this.$t(`global.months.${toLower(currentDate.format('MMMM'))}`),
            year: currentDate.format('YYYY'),
          }).toString();
        }
      }
      previousDate = currentDate;
    });
    return data;
  }

  get showScrollToFinishBtn(): boolean {
    return !this.hideScrollToFinishBtn && !this.endOfScrollPosition && !!this.conversation.messages.length;
  }

  get isBuiltInMode(): boolean {
    return this.sessionService && this.sessionService.builtInMode;
  }

  get dashboardProps(): DashboardOptions {
    let heightChat = '154px';
    if (this.expand) {
      heightChat = 'calc(100vh - 348px)';
    } else if (window.innerHeight > 800) {
      heightChat = '208px';
    }
    return {
      inline: true,
      width: '100%',
      height: heightChat,
      proudlyDisplayPoweredByUppy: false,
      metaFields: [{ id: 'description', name: 'Descrição', placeholder: '' }],
      hideUploadButton: false,
      hideRetryButton: true,
    };
  }

  get btnConversation(): string {
    if (this.conversation.prospect || (this.conversation.cliente
      && this.conversation.cliente.tipo === ClientTypeEnum.Prospect)) {
      return 'P';
    }
    if (this.conversation.cliente && (this.conversation.cliente.tipo === ClientTypeEnum.Client
      || this.conversation.cliente.type === ClientTypeEnum.Client)) {
      return 'C';
    }
    return '?';
  }

  get hasExistingContacts(): boolean {
    return this.existingContacts && this.existingContacts.length > 0;
  }

  get disableContactDialog(): boolean {
    return !this.conversation.cliente && !this.conversation.prospect && this.hasExistingContacts;
  }

  getNomeAtendenteResponsavel(): string {
    let nomeAtendenteResponsavel = '';
    if (!this.conversation.nomeAtendenteResponsavel) {
      if (this.conversation.cliente) {
        nomeAtendenteResponsavel = this.conversation.cliente.nomeAtendenteResponsavel || '';
      }
      if (this.conversation.prospect) {
        nomeAtendenteResponsavel = this.conversation.prospect.nomeAtendenteResponsavel || '';
      }
    }
    if (this.conversation.nomeAtendenteResponsavel) {
      nomeAtendenteResponsavel = this.conversation.nomeAtendenteResponsavel || '';
    }
    if (nomeAtendenteResponsavel) {
      return `${this.$t('crm.chat.dialog.responsibleAttendant.label')}: ${nomeAtendenteResponsavel}`;
    }
    return `${this.$t('crm.chat.dialog.responsibleAttendant.notAssociated')}`;
  }

  async setConversationClient(contact: ContactModel): Promise<void> {
    if (this.conversation) {
      const conversa = cloneDeep(this.conversation);
      conversa.contato = contact;
      conversa.tipo = contact.tipoCliente === 'Cliente'
        ? ConversationTypeEnum.Client : ConversationTypeEnum.Prospect;
      if (contact.tipoCliente === 'Cliente') {
        conversa.cnpj = contact.cnpj;
        conversa.cliente = new ClientModel();
        conversa.cliente.cnpjCpf = contact.cnpj;
        if (contact.nomeCliente) conversa.cliente.nome = contact.nomeCliente;
      } else {
        conversa.prospect = new ProspectModel();
        if (contact.idProspect) conversa.prospect.codProspect = contact.idProspect.toString();
        if (contact.nomeCliente) conversa.prospect.razaoSocial = contact.nomeCliente;
      }

      await this.conversationService.setClientProspectConversation(conversa,
        conversa.tipo === ConversationTypeEnum.Client ? null : contact.idProspect);

      this.conversation = cloneDeep(conversa);
      this.onSetConversationClient();
    }
  }

  private setLoader(active: boolean): void {
    setTimeout(
      () => {
        if (active) {
          this.loader = this.$loading.show({
            container: this.$refs.chatDialogBody,
            canCancel: false,
          });
        } else if (this.loader) {
          this.loader.hide();
        }
      },
      !this.$refs.chatDialogBody ? 750 : 0,
    );
  }
}
